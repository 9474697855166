const map = new Map<string, string>([
  [ '&lt;', '<' ],
  [ '&gt;', '>' ],
  [ '&quot;', '"' ],
  [ '&apos;', '\'' ],
  [ '&amp;', '&' ],
  [ '&nbsp;', ' ' ],
  [ '&nbsp', ' ' ],
])

// removes all html instances from the string like &nbsp; and br
// ATTN do NOT use with user-input data. It covers only small amount of cases. Use for messages only
const sanitizeForAnalytics = (input: string): string => {
  if (!input || typeof input !== 'string') {
    return input
  }

  let result = input

  // replace all html tags
  result = result.replace(/<([^<>]*?)>/g, ' ')

  // replace html entities
  map.forEach((to, from) => {
    result = result.replaceAll(from, to)
  })

  // replace repeating spaces
  result = result.replace(/\n\r/g, ' ').replace(/\s{2,}/g, ' ').trim()

  return result
}

export default sanitizeForAnalytics
