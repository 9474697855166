/**
 * Generates array of numbers from the beggining to end
 * e.g range(1, 3) → [ 1, 2, 3 ]
 */
const range = (begin: number, end: number): number[] => {
  begin = Number(begin)
  end = Number(end)

  if (Number.isNaN(begin) || Number.isNaN(end)) {
    throw new Error('array.range accepts two aguments, both should be numbers')
  }

  const isDownwards = begin > end
  const length = (isDownwards ? begin - end : end - begin) + 1

  return new Array(length).fill(null)
    .map((v, i) => isDownwards ? begin - i : begin + i)
}

/**
 * Shuffles the array randomly
 * e.g [ 0, 1, 2, 3 ] → [ 2, 1, 0, 3 ]
 */
// TODO if return value is same as passed then do shuffle again
const shuffle = <T = any>(array: T[]): T[] => {
  if (Array.isArray(array)) {
    let newArray = [ ...array ]

    // if array is suit for shuffle, it's small enougth, return it right away
    if (newArray.length <= 1) {
      return newArray
    }

    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [ newArray[i], newArray[j] ] = [ newArray[j], newArray[i] ]
    }

    if (newArray.join() === array.join()) {
      newArray.reverse()
    }

    return newArray
  }

  throw new Error('array.shuffle accepts only arrays in first argument')
}


export default {
  range,
  shuffle,
}
