type Hex = string

type Hue = number
type Light = number
type Saturation = number

type HSL = [ Hue, Saturation, Light ]

/**
 * Converts hex color to HSL array
 * e.g '#E73DAC' → [ 321, 78, 57 ]
 */
const hexToHslArray = (hex: Hex): HSL => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  let r = parseInt(result[1], 16) / 255
  let g = parseInt(result[2], 16) / 255
  let b = parseInt(result[3], 16) / 255

  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)
  let h: number
  let s: number
  let l: number = (max + min) / 2

  if (max === min) {
    h = 0
    s = 0
  }
  else {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break
      case g: h = (b - r) / d + 2; break
      case b: h = (r - g) / d + 4; break
    }
    h /= 6
  }

  h = Math.round(360 * h)
  s *= 100
  s = Math.round(s)
  l *= 100
  l = Math.round(l)

  return [ h, s, l ]
}


export default {
  hexToHslArray,
}
