import { devicePx } from 'helpers/devicePx'


type ScrollToElementOptions = {
  compensation?: number // in rem
  smooth?: boolean
  onlyUp?: boolean
  scrollableElement?: HTMLElement
}

const scrollToElement = (elementOrSelector: HTMLElement | string, options?: ScrollToElementOptions): void => {
  const {
    compensation = 0,
    smooth = false,
    onlyUp = false,
    scrollableElement,
  } = options || {}

  const element: HTMLElement = typeof elementOrSelector === 'string'
    ? document.querySelector(elementOrSelector)
    : elementOrSelector

  if (element) {
    const container = scrollableElement || window
    const offset = scrollableElement ? scrollableElement.scrollTop : window.scrollY
    const elementRect = element.getBoundingClientRect()
    const elementOffsetTop = elementRect.top + offset
    const offsetTop = (elementOffsetTop + devicePx(compensation)) || 0

    if (onlyUp && offset <= elementOffsetTop) {
      return
    }

    if (smooth) {
      try {
        container.scroll({
          top: offsetTop,
          left: 0,
          behavior: 'smooth',
        })
      }
      catch (err) {
        container.scroll(0, offsetTop)
      }
    }
    else {
      container.scroll(0, offsetTop)
    }
  }
  else {
    console.warn(`Element '${elementOrSelector}' not found`)
  }
}


export default scrollToElement
