interface RemoveKeys {
  <T extends object, K extends (keyof T)[]>
  (object: T, ...keys: K): {
    [ K2 in Exclude<keyof T, K[number]>]: T[K2]
  }
}

const removeKeys: RemoveKeys = (object, ...keys) => {
  const copy = Object.assign({}, object)

  for (let key of keys) {
    delete copy[key]
  }

  return copy
}

const isObject = (item: any) => {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

/**
 * Deep merge of two objects
 *
 * If a key exists in both objects:
 * - and both values are objects, the two values will be recursively merged
 * - otherwise the value from the second object will be used
 *
 * e.g. { name: 'Fred', health: { age: 10 } }, { name: 'Freddy', health: { died: true } } → { name: 'Freddy', helath: { age: 10, died: true } }
 */
const deepmerge = <T1 = any, T2 = any>(target: T1, source: T2): T1 & T2 => {
  if (!isObject(target) || !isObject(source)) {
    return target as any
  }

  const copy: object = Object.assign({}, target)

  for (const key of Object.keys(source)) {
    if (isObject(source[key]) && isObject(copy[key])) {
      copy[key] = deepmerge(copy[key], source[key])
    }
    else {
      copy[key] = source[key]
    }
  }

  return copy as any
}

type MergeObject = {
  [key: string]: any
}

type MergeAllKeys<T> = T extends any ? keyof T : never;

type MergeIndexValue<T, K extends PropertyKey, D = never> = T extends any
  ? K extends keyof T
    ? T[K]
    : D
  : never;

type MergePartialKeys<T, K extends keyof T> = Omit<T, K> &
Partial<Pick<T, K>> extends infer O
  ? { [P in keyof O]: O[P] }
  : never;

type TFunction = (...a: any[]) => any;

type TPrimitives =
  | string
  | number
  | boolean
  | bigint
  | symbol
  | Date
  | TFunction;

type TMerged<T> = [T] extends [Array<any>]
  ? { [K in keyof T]: TMerged<T[K]> }
  : [T] extends [TPrimitives]
    ? T
    : [T] extends [object]
      ? MergePartialKeys<{ [K in MergeAllKeys<T>]: TMerged<MergeIndexValue<T, K>> }, never>
      : T;


const merge = <T extends MergeObject[]>(...objects: T): TMerged<T[number]> => {
  return objects.reduce((prev, next) => {
    return deepmerge(prev, next)
  }, {}) as any
}


export default {
  removeKeys,
  merge,
}
