// converts iPhone XR size in pixels to vwx device size
export const devicePx = (value: number): number => {
  if (typeof window !== 'undefined' && Boolean(window.__DEVICE__) && typeof window.innerWidth === 'number') {
    // for bots we always use 1
    if (window.__DEVICE__.isBot) {
      return Math.round(value)
    }

    // TODO add cache here - added on 2021-10-20 by maddoger
    const multiplier = window.__DEVICE__.isMobile && !window.__DEVICE__.isTablet ? window.innerWidth * 0.0024155 : 1

    return Math.round(value * multiplier)
  }

  return value
}

// converts iPhone XR size in pixels (rem) to vw string for image sizes argument
export const deviceVm = (value: number): string => {
  return `${Math.round(value / 414 * 100)}vw`
}
