export type QueryCoupon = 'c1' | 'c2' | 'c3' | 'c4' | 'c5' | 'c6' | 'cfr' | 'none'

export const queryCoupons: Record<QueryCoupon, string> = {
  c1: 'HD15',
  c2: 'HD25',
  c3: 'HD30',
  c4: 'HD40',
  c5: 'HD50',
  c6: 'HD60',
  cfr: 'FREE_TRIAL',
  none: null,
}

const getCouponFromQueryParams = (queryCoupon: string) => {
  return queryCoupons[queryCoupon]
}


export default getCouponFromQueryParams
