import type { TradingItem } from 'typings/graphql'


const getTradingItemDiscount = (tradingItem: Pick<TradingItem, 'prices'>): number => {
  const discountPrice = tradingItem.prices?.discountPrice?.amountCents
  const standardPrice = tradingItem.prices?.standardPrice?.amountCents

  if (typeof discountPrice === 'number' && typeof standardPrice === 'number' && discountPrice < standardPrice) {
    return Math.round((1.0 - discountPrice / standardPrice) * 100.0)
  }

  return 0
}

export default getTradingItemDiscount
