const formatFullName = ({ firstName = '', lastName = '' }: { firstName: string, lastName: string }): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }

  if (!firstName && !lastName) {
    return `Scentbird Account`
  }

  return firstName || lastName
}


export default formatFullName
