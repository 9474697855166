import date from 'date'
import cookieStorage from 'cookie-storage'


type Options = {
  cookieName: string
  countPerDay: number // how many times a popup  per 24 hours
  isSetCookieOnClose: boolean // Temporary option for CRO-475 ab test
}

// special helper to check visibility conditions based on cookie
const checkUpsellModalCookie = (options: Options) => {
  const { cookieName, countPerDay, isSetCookieOnClose } = options

  const cookie = cookieStorage.getItem(cookieName)
  const { startDate, count = 0 } = cookie || {}

  // flag if modal should be visible based on timing condition
  let isVisible = false
  // onClose handler to track a show
  let onClose: () => void = () => {}
  let onShow: () => void = () => {}

  // we show 3 times each 24 hours. 24 hours starts from the first show
  if (count < countPerDay || date().diff(date(startDate), 'hours') > 24) {
    isVisible = true

    const setCookie = () => {
      cookieStorage.setItem(cookieName, {
        startDate: count === 0 || count >= countPerDay ? date().toISOString() : startDate,
        count: count >= countPerDay ? 1 : count + 1,
      })
    }

    if (isSetCookieOnClose) {
      onClose = setCookie
    }
    else {
      onShow = setCookie
    }
  }

  return {
    isVisible,
    onClose,
    onShow,
  }
}

export default checkUpsellModalCookie

