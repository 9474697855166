const formatSize = (volume: number | string, unit: string): string => {
  if (!volume || unit === 'pcs') {
    return ''
  }

  return `${volume} ${unit}`
}


export default formatSize
