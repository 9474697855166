import links from 'links'
import logger from 'logger'

import {
  openChangePlanErrorModal,
  openErrorModal,
  openFraudModal,
  openNetworkErrorModal,
  openServerErrorModal,
} from 'compositions/modals/ErrorModal/ErrorModal'


// if you need to cancel the promise and break the flow, you can throw this error
export class CancelledPromiseError extends Error {
  public constructor(message: string = 'CancelledPromiseError') {
    super(message)
  }
}

// error with a new message but with cause inside
export class CausedError extends Error {
  public cause: any
  public traceId: string

  public constructor(cause: string)
  public constructor(cause: { message: string }, message?: string)
  public constructor(cause: any, message?: string)
  public constructor(cause: any, message?: string) {
    if (message) {
      super(message)
    }
    else if (typeof cause === 'string') {
      super(cause)
    }
    else {
      super(cause?.message)
    }

    this.cause = cause

    if (!this.message) {
      this.message = this.name
    }

    // graphql errors can have traceId field
    if (this.cause?.traceId) {
      this.traceId = this.cause.traceId
    }
  }
}

// export class HttpError extends ExtendedError<{ statusCode: number }, { statusCode: number }> {}

// error with user message and reason
export class ValidationError extends CausedError {
  public constructor(cause: any, message: string = 'ValidationError') {
    super(cause, message)
  }
}

// special class for server errors, when users shouldn't see the actual error
export class ServerError extends CausedError {}

// error for internal use only, it will be logged, but will not be in notification
export class SilentError extends CausedError {
}

// error with user message and reason
export class UserError extends CausedError {}

// special class for graphql error, it is based on ServerError because it shouldn't be shown to a user
export class GraphQLError extends ServerError {
}

// special class for recurly errors
export class PaymentTokenError extends UserError {
}


// this function helps to process different errors and eliminate code duplication
// TODO think about isomorthic implementation (to provide redirect from server, something instead of openModal, etc) - added on 08.02.2022 by sonatskiy
export const processError = (error: any, infoMessage?: string): void => {
  if (infoMessage) {
    logger.info(error, infoMessage)
  }

  if (error instanceof CancelledPromiseError) {
    logger.info(error)
    return
  }

  if (error instanceof ValidationError) {
    // do nothing, usually it's a form validation
    return
  }

  if (error instanceof SilentError) {
    logger.info(error)
    return
  }

  if (error instanceof UserError) {
    if (__CLIENT__ && error.cause?.securityErrorCode === 'NOT_AUTHENTICATED') {
      const url = `${links.login}?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`

      window.location.assign(url)
      return
    }

    if (error.cause?.changePlanErrorCode === 'CHANGE_PLAN_DISABLED') {
      openChangePlanErrorModal(error.cause.action)
      return
    }

    if (!infoMessage) {
      logger.warn(error)
    }

    if (__CLIENT__) {
      openErrorModal({
        message: error.message,
        traceId: error.traceId,
      })
    }
    return
  }

  // special case for Apollo errors, which are thrown because of a network error
  if (error.networkError) {
    if (!infoMessage) {
      logger.info(error)
    }

    if (__CLIENT__) {
      if (error?.networkError?.statusCode === 451) {
        openFraudModal()
        return
      }

      // read traceId from the response header
      openNetworkErrorModal({
        traceId: error.networkError.response?.headers?.get('x-trace-id'),
      })
    }

    return
  }

  logger.error(error)

  if (__CLIENT__) {
    openServerErrorModal({
      traceId: error.traceId,
    })
  }
}
