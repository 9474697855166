const getPlanDifferencePercent = (selectedPlanProductPrice: number, productPrice: number) => {
  if (!selectedPlanProductPrice) {
    return null
  }

  const discount = Math.round(100 * (selectedPlanProductPrice - productPrice) / selectedPlanProductPrice)

  return discount > 0 ? `${discount}%` : null
}


export default getPlanDifferencePercent
