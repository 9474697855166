// ATTN we use these constants in data dump
const abTests = {
  // CRO tests
  tyHideAddedProduct: 'ty:hideAddedProduct', // CRO-270
  paymentOrderPreviewDropdown: 'payment:orderPreviewDropdown', // CRO-417
  globalSubscribeBannerUpdate: 'global:subscribeBannerUpdateV2', // CRO-488
  scentProfileApplePay: 'scentProfile:applePay', // CRO-445
  popupHidePopups: 'popup:hidePopups', // CRO-475
  popupFirstTimeVisitModalRedesign: 'popup:firstTimeVisitModalRedesign', // CRO-490
  homeWhatYouGet: 'home:whatYouGet', // CRO-393
  heroServiceAndOfferDescriptionDivision: 'home:heroServiceAndOfferDescriptionDivision', // CRO-402
  globalHideCurrencySign: 'global:hideCurrencySign', // CRO-435
  paymentApplePayOnTop: 'payment:appleOnTop', // CRO-446
  paymentBrandedColors: 'payment:brandedColors', // CRO-487
  tyDriftCarImage: 'ty:driftCarImage', // CRO-495
  popupAlreadyTriedThis: 'popup:alreadytriedthis', // CRO-408
  homeQuizSection: 'home:quizSection', // CRO-509
  homeCirclesWithBottles: 'home:circlesWithBottles', // CRO-505
  homeHeroImage: 'home:heroImage', // CRO-388
  mainEstimatedDelivery: 'main:estimatedDelivery', // CRO-477
  quizCopyUpdate: 'quiz:copyUpdate', // CRO-481
  paymentButtonCopy: 'payment:buttonCopy', // CRO-444
  queueWidgetRedesign: 'queue:widgetRedesign', // CRO-523
  selectSection: 'recommendations:selectSection', // CRO-463
  spAddToQueuePopupRedesign: 'sp:addToQueuePopupRedesign', // CRO-535
  subscriptionBannerRedesign: 'subscription:bannerRedesign', // CRO-506
  // PF tests
  constructorRecommendations: 'constructorRecommendations', // PF-2319
  aiSummaryReview: 'aiSummaryReview', // PF-2847
  registerFieldNoScaling: 'register:fieldNoScaling', // CRO-518
  // EP tests
  miniBottlesMainImageChange: '20ml:mainImageChange', // EP-3069
} as const

export type AbTestName = typeof abTests[keyof typeof abTests]


export default abTests
