/**
 * Transforms multiline string to one liner and trims it
 * e.g. '  this\n is the text    ' → 'this is the text'
 */
const multiLineToOneLine = (string: string): string =>
  string.replace(/\n\s*|\s{2,}/mg, ' ').trim()

/**
 * Capitalizes, transforms first letter to uppercase, string
 * e.g. dumbo → Dumbo
 */
const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Decapitalizes, transforms first letter to lowercase, string
 * e.g. ScentType -> scentType
 */
const decapitalize = (string: string): string =>
  string.split('').map((l, i) => i === 0 ? l.toLowerCase() : l).join('')


/**
 * Normalizes string and removes all combining diacritical marks
 * e.g. ScentType -> scentType
 */
const normalizeClear = (string: string): string =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/œ/g, 'oe')
    .replace(/æ/g, 'ae')


const getFirstLetter = (string: string): string => {
  const normalizedString = normalizeClear(string).replace(/[^\d\w]/g, '')

  return normalizedString.charAt(0).toUpperCase()
}

export default {
  multiLineToOneLine,
  decapitalize,
  capitalize,
  normalizeClear,
  getFirstLetter,
}
