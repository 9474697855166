const focusableElements = `
  a[href]:not([disabled]), 
  button:not([disabled]), 
  input[type="text"]:not([disabled]),
  input[type="radio"]:not([disabled]), 
  input[type="checkbox"]:not([disabled]), 
  textarea:not([disabled]), 
  select:not([disabled]),
  div[role="button"]:not([disabled])
`

const getFocusableChildren = (element: HTMLElement): NodeListOf<HTMLElement> => element.querySelectorAll(focusableElements)


export default getFocusableChildren
