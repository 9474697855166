import type { Label } from 'typings/graphql'


type Product = Partial<Pick<ProductFragment.Base, 'rebrandLabels' | 'upchargePrice'>>

const formatProductLabelsForAnalytics = ({ product, intl }: { product: Product, intl: Intl.Manager }): string[] => {
  if (!product) {
    return []
  }

  const { rebrandLabels, upchargePrice } = product
  let result: string[] = []

  if (rebrandLabels) {
    Object.keys(rebrandLabels).forEach((type) => {
      if (Array.isArray(rebrandLabels[type])) {
        rebrandLabels[type].forEach((label: Label) => {
          if (label === 'UPCHARGE') {
            return
          }

          result.push(label)
        })
      }
    })
  }

  if (upchargePrice) {
    result.push(`UPCHARGE +${intl.formatPrice(upchargePrice, { removeZeroCents: true })}`)
  }

  return result
}


export default formatProductLabelsForAnalytics
