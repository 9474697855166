const sanitizeRedirectUrl = (url: string): string => {
  if (!url) {
    return url
  }

  // remove domain from the url, because we can redirect only to our domain
  const sanitized = url.replace(/^([a-z]*?):?\/\/([^\/?]*)([\/?]?)/gi, '$3')

  // reset invalid urls
  if (!sanitized) {
    return null
  }

  // forbid all urls with back slash or which starts with double slash
  if (sanitized.match(/%5C/) || sanitized.match(/\\/) || sanitized.match(/^\/{2,}/)) {
    return null
  }

  const firstChar = sanitized.charAt(0)
  if (firstChar !== '/' && firstChar !== '?') {
    return null
  }

  return sanitized
}

export default sanitizeRedirectUrl
