export default {
  required: {
    en: 'Required',
  },
  minLength: {
    en: 'Must be at least {length} characters',
  },
  maxLength: {
    en: 'Must be no more than {length} characters',
  },
  email: {
    en: 'Valid email address required',
  },
  date: {
    en: 'Date is not valid',
  },
  password: {
    en: 'Password must include alphanumeric and !@#$%^&*()_+-= characters',
  },
  pastDate: {
    en: 'Date must be in the past',
  },
  birthdayDate: {
    en: 'Date must be after 1907',
  },
  futureDate: {
    en: 'Date must be in the future',
  },
  notTodayDate: {
    en: 'You can’t enter a current date',
  },
  userName: {
    en: 'Only letters and spaces are allowed',
  },
  streetAddress: {
    en: 'Must be a valid street address',
  },
  zipCode: {
    en: 'Must be a valid zip code',
  },
  postalCode: {
    en: 'Must be a valid postal code',
  },
  nonMilitary: {
    en: 'We don’t ship to US Military APO, FPO & DPO addresses',
  },
  phone: {
    en: 'Must be a valid phone number',
  },
  passwordMatch: {
    en: 'Passwords should match',
  },
}
