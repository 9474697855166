const replaceMap = {
  '<': '\\u003c',
  '>': '\\u003e',
}

const safeJsonStringify = (object: any): string => {
  return JSON.stringify(object).replace(/[<>]/g, (c) => replaceMap[c])
}


export default safeJsonStringify
