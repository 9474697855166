import type { Volume } from 'typings/graphql'


type TradingItemRequiredFields = Pick<TradingItemFragment.Base, 'volume'>

type GetTradingItemsByVolumeParams<T extends TradingItemRequiredFields = TradingItemFragment.Base> = {
  tradingItems: T[]
  filtersByVolume?: Volume[]
}

const getTradingItemsByVolume = <T extends TradingItemRequiredFields = TradingItemFragment.Base>({
  tradingItems,
  filtersByVolume,
}: GetTradingItemsByVolumeParams<T>): T[] => {
  if (!tradingItems?.length || !filtersByVolume?.length) {
    return tradingItems
  }

  return tradingItems.filter(({ volume }) => {
    return filtersByVolume.some((filterByVolume) => {
      return volume.volume === filterByVolume.volume && volume.unit === filterByVolume.unit
    })
  })
}


export default getTradingItemsByVolume
