const mocks = {
  membershipUids: [
    '00000000-0000-0000-0000-0000000008ac',
    '00000000-0000-0000-0000-000000000c05',
    '00000000-0000-0000-0000-000000002885',
    '00000000-0000-0000-0000-000000002fe3',
    '00000000-0000-0000-0000-0000000013aa',
  ],
  discountUids: [
    '00000000-0000-0000-0000-000000000e36',
  ],
}

export default mocks
