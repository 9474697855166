import clsx, { type ClassValue } from 'classnames'
import { twMerge } from 'tailwind-merge'


export const cx = clsx

export const tw = twMerge

export const twcx = (...items: ClassValue[]) => {
  return twMerge(clsx(items))
}
