import config from 'config'


const getReferrerLandingPageUrl = (path: string) => {
  const host = config.publicUrl.replace(/^https:\/\/(www\.)?/, '')

  const pathname = /^\/referral\//.test(path)
    // TODO probably, it isn't correct to replace only "scentbirduserXXX" coz we have named personalCode for some influencers - added on 06.04.2022 by sonatskiy
    ? path.replace(/scentbirduser.+/, '')
    : path

  return `${host}${pathname}`
}


export default getReferrerLandingPageUrl
