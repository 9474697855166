import type { Volume } from 'typings/graphql'


type TradingItemRequiredFields = Pick<TradingItemFragment.Base, 'section' | 'volume'>

type GetTradingItemByVolumeParams<T extends TradingItemRequiredFields = TradingItemFragment.Base> = {
  tradingItems: T[]
  filtersByVolume?: Volume[]
  isEcommerce?: boolean
}

const getTradingItemByVolume = <T extends TradingItemRequiredFields = TradingItemFragment.Base>({
  tradingItems,
  filtersByVolume,
  isEcommerce = false,
}: GetTradingItemByVolumeParams<T>): T => {
  if (!tradingItems?.length || !filtersByVolume?.length) {
    return undefined
  }

  return tradingItems.find(({ section, volume }) => {
    const hasMatchingSection = isEcommerce ? section === 'Ecommerce' : section === 'Subscription'
    const hasMatchingVolume = filtersByVolume.some((filterByVolume) => {
      return volume.volume === filterByVolume.volume && volume.unit === filterByVolume.unit
    })

    return hasMatchingVolume && hasMatchingSection
  })
}


export default getTradingItemByVolume
