export default {
  maxCountOfPaymentMethods: 9,
  maxCountOfShippingAddresses: 5,
  fragrancesAmount: 700,
  productsRemainingLimit: 300,
  defaultCaseRetailPrice: 1595,
  defaultDriftRetailPrice: 1295,
  defaultCandleRetailPrice: 4500,
  defaultSamplesRetailPrice: 990,
}
