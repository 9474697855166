import xss, { type IFilterXSSOptions } from 'xss'


// very strict, remove every tag
const defaultOptions: IFilterXSSOptions = {
  whiteList: {
    br: [],
  },
  stripIgnoreTag: false,
}

const sanitizeHtml = (source: string, options: IFilterXSSOptions = defaultOptions): string => {
  return xss(source, options)
}


export default sanitizeHtml
