interface Address {
  country: string
  city: string
  postalCode: string
  region: string
  street1: string
  street2?: string
}

type Options = {
  regionOnNewLine?: boolean
}

const cleanParams = (input: Address): Address => Object.keys(input).reduce((result, key) => {
  result[key] = input[key] ? (String(input[key]).trim() || '') : ''

  return result
}, {} as Address)

const formatStreetApt = (street1: string, street2: string, isCanada: boolean) => {
  if (!street1 || !street2) {
    return street1 || street2
  }

  const isNumber = /^\d+$/.test(street2)

  // in case of apt is just a number
  if (isNumber) {
    return isCanada ? `${street2}-${street1}` : `${street1} #${street2}`
  }

  // in case of apt includes words - APT, ROOM, FLOOR, BOX, etc.
  return isCanada ? `${street2}, ${street1}` : `${street1}, ${street2}`
}

const formatAddress = (address: Address, { regionOnNewLine }: Options = {}): string => {
  if (!address || !Object.keys(address).length) {
    return ''
  }

  const { street1, street2, city, region, postalCode, country } = cleanParams(address)

  const isCanada = country === 'CA' || country === 'Canada'

  const streetAndCity = [
    formatStreetApt(street1, street2, isCanada),
    city,
  ]
    .filter(Boolean)
    .join(', ')

  const stateWithZip = [
    regionOnNewLine ? '<br />' : '',
    region,
    postalCode,
  ]
    .filter(Boolean)
    .join(' ')

  return [ streetAndCity, stateWithZip, country ]
    .filter(Boolean)
    .join(', ')
}


export default formatAddress
